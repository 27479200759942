import Cookies from 'js-cookie';
import type { UserInfo, UserType } from '@/store/types';

export const sessionKey = 'user-info';
export const tokenKey = 'authorized-token';

export interface DataInfo {
  accessToken: string;
  userInfo: UserInfo;
}

/**
 * 从存储中获取token
 */
export function getToken() {
  const info = getSessionDataInfo();
  return Cookies.get(tokenKey) ? Cookies.get(tokenKey) : info?.token;
}

export function getAccount() {
  const info = getSessionDataInfo();
  return info.account;
}
/**
 * 设置token到存储
 * @param dataInfo
 */
export function setToken(dataInfo: UserType) {
  Cookies.set(tokenKey, dataInfo.token);
  sessionStorage.setItem(sessionKey, JSON.stringify(dataInfo));
}

export function getSessionDataInfo(): Partial<UserType> {
  try {
    return JSON.parse(sessionStorage.getItem(sessionKey) ?? '{}') as UserType;
  } catch {
    return {};
  }
}

/**
 * 清除token信息
 */
export function removeToken() {
  Cookies.remove(tokenKey);
  sessionStorage.clear();
}

/**
 * 格式化token（jwt格式）
 */
export const formatToken = (token: string): string => {
  return 'Bearer ' + token;
};
