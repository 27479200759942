import { useUserStore } from '@/store/modules/useUserStore';
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import NProgress from 'nprogress';

/**
 * meta
 * - title 标题
 * - showLink 显示
 * - isWhite 是否白名单
 * - isExternal 是否外部页面
 * - redirect 路由重定向
 * - mobileRedirect 移动端重定向
 * - pcRedirect 电脑端重定向
 * - notScroll 不需要滚动组件
 */

export const routerMenus: RouteRecordRaw[] = [
  {
    path: '/user-management',
    name: 'user-management',
    meta: {
      title: '会员管理',
      icon: 'icon-yonghuguanli',
      order: 1,
    },
    children: [
      {
        path: '/user-management/user-list',
        name: 'user-list',
        meta: {
          title: '会员列表',
        },
        component: () => import('@/views/user-management/UserList/index.vue'),
      },
    ],
  },
  {
    path: '/short-management',
    name: 'short-management',
    meta: {
      title: '短剧管理',
      icon: 'icon-duanju',
      order: 1,
    },
    redirect: '/short-management/short-list',
    children: [
      {
        path: '/short-management/short-label',
        name: 'short-label',
        meta: {
          title: '短剧标签',
        },
        component: () => import('@/views/short-management/ShortLabel/index.vue'),
      },
      {
        path: '/short-management/short-list',
        name: 'short-list',
        meta: {
          title: '短剧列表',
        },
        component: () => import('@/views/short-management/ShortList/index.vue'),
      },
      {
        path: '/short-management/short-list-edit',
        name: 'short-list-edit',
        meta: {
          title: '短剧列表',
          showLink: false,
        },
        component: () => import('@/views/short-management/ShortList/ShortListEdit.vue'),
      },
      {
        path: '/short-management/videos-source/:id',
        name: 'videos-source',
        meta: {
          title: '视频资源',
          showLink: false,
        },
        component: () => import('@/views/short-management/ShortList/VideosSource/index.vue'),
      },
      {
        path: '/short-management/videos-source-edit',
        name: 'videos-source-edit',
        meta: {
          title: '视频资源',
          showLink: false,
        },
        component: () => import('@/views/short-management/ShortList/VideosSource/Edit.vue'),
      },
    ],
  },
  {
    path: '/block-management',
    name: 'block-management',
    meta: {
      title: '首页装修',
      order: 2,
      icon: 'icon-lanmuguanli',
    },
    children: [
      {
        path: '/block-management/banner',
        name: 'banner',
        meta: {
          title: 'Banner轮播',
        },
        component: () => import('@/views/short-block-management/Banner/Banner.vue'),
      },
      {
        path: '/block-management/short-block',
        name: 'short-block',
        meta: {
          title: '首页版块',
        },
        component: () => import('@/views/short-block-management/ShortBlock/ShortBlock.vue'),
      },
      {
        path: '/block-management/detail',
        name: 'detail',
        meta: {
          showLink: false,
        },
        component: () => import('@/views/short-block-management/ShortBlock/BlockDetail.vue'),
      },
      {
        path: '/block-management/block-detail-edit',
        name: 'block-detail-edit',
        meta: {
          showLink: false,
        },
        component: () => import('@/views/short-block-management/ShortBlock/BlockDetailEdit.vue'),
      },
      {
        path: '/block-management/block-edit',
        name: 'block-edit',
        meta: {
          showLink: false,
        },
        component: () => import('@/views/short-block-management/ShortBlock/BlockEdit.vue'),
      },
      {
        path: '/block-management/edit',
        name: 'bannerEdit',
        meta: {
          showLink: false,
        },
        component: () => import('@/views/short-block-management/Banner/Edit.vue'),
      },
    ],
  },
  {
    path: '/order-management',
    name: 'order-management',
    meta: {
      title: '订单管理',
      order: 3,
      icon: 'icon-dingdan',
    },
    component: () => import('@/views/order-management/index.vue'),
  },
  {
    path: '/Recharge-management',
    name: 'recharge-management',
    meta: {
      title: '充值管理',
      icon: 'icon-chongzhiguanli',
    },
    children: [
      {
        path: '/Recharge-management/coins-Recharge',
        name: 'coinsRecharge',
        meta: {
          title: '金币配置',
        },
        component: () => import('@/views/recharge-management/CoinsRecharge/CoinsRecharge.vue'),
      },
      {
        path: '/Recharge-management/coins-Recharge-edit',
        name: 'coinsRechargeEdit',
        meta: {
          title: '金币充值',
          showLink: false,
        },
        component: () => import('@/views/recharge-management/CoinsRecharge/Edit.vue'),
      },
      {
        path: '/Recharge-management/vipManagement',
        name: 'vipManagement',
        meta: {
          title: 'VIP配置',
        },
        component: () => import('@/views/recharge-management/VipManagement/index.vue'),
      },
      {
        path: '/Recharge-management/vipManagement/add',
        name: 'vipManagementAdd',
        meta: {
          showLink: false,
        },
        component: () => import('@/views/recharge-management/VipManagement/add.vue'),
      },
    ],
  },
  {
    path: '/statistics-management',
    name: 'statistics-management',
    meta: {
      title: '统计管理',
      order: 5,
      icon: 'icon-tongji',
    },
    children: [
      {
        path: '/statistics-management/delivery',
        name: 'statistics-management-delivery',
        meta: {
          title: '投放统计',
        },
        component: () => import('@/views/statistics-management/Delivery/index.vue'),
      },
      {
        path: '/statistics-management/track',
        name: 'statistics-management-track',
        meta: {
          title: '埋点统计',
        },
        component: () => import('@/views/statistics-management/Track/index.vue'),
      },
      {
        path: '/statistics-management/sales',
        name: 'statistics-management-sales',
        meta: {
          title: '销售统计',
        },
        component: () => import('@/views/statistics-management/Sales/index.vue'),
      },
      {
        path: '/statistics-management/Member',
        name: 'Member',
        meta: {
          title: '会员统计',
        },
        component: () => import('@/views/statistics-management/Member/index.vue'),
      },
      {
        path: '/statistics-management/recharge',
        name: 'recharge',
        meta: {
          title: '充值统计',
        },
        component: () => import('@/views/statistics-management/Recharge/index.vue'),
      },
      {
        path: '/statistics-management/short',
        name: 'short',
        meta: {
          title: '短剧统计',
        },
        component: () => import('@/views/statistics-management/Short/index.vue'),
      },
      {
        path: '/statistics-management/Video',
        name: 'video',
        meta: {
          title: '视频统计',
        },
        component: () => import('@/views/statistics-management/Video/index.vue'),
      },
    ],
  },
  {
    path: '/app-setting',
    name: 'app-setting',
    meta: {
      title: 'APP设置',
      order: 6,
      icon: 'icon-app',
    },
    component: () => import('@/views/app-setting/index.vue'),
  },
];
const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_HISTORY),
  routes: [
    {
      path: '/login',
      name: 'login',
      meta: {
        title: 'ShortYes-10钟上线你的海外短剧APP',
      },
      component: () => import('@/views/login/index.vue'),
    },
    {
      path: '/',
      name: 'Index',
      component: () => import('@/layout/index.vue'),
      redirect: '/short-management',
      children: routerMenus,
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/',
    },
  ],
});
router.beforeEach((to, from, next) => {
  NProgress.start();
  /** 如果已经登录并存在登录信息后不能跳转到路由白名单，而是继续保持在当前页面 */
  const token = useUserStore().token;
  if (to.path !== '/login' && !token) {
    next({
      path: '/login',
    });
  } else {
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
