import { defineStore } from 'pinia';
import { store } from '@/store';
import router from '@/router';
import { setToken, getToken, getAccount, getSessionDataInfo, removeToken } from '@/utils/auth';

export const useUserStore = defineStore('userStore', {
  state: () => {
    return {
      token: getToken() ?? '',
      email: getAccount() ?? '',
      project_id: getSessionDataInfo().project_id ?? '',
    };
  },
  actions: {
    setUserInfo(token: string, account: string, id: number) {
      this.token = token;
      this.email = account;
      this.project_id = id;
      setToken({ token, account, project_id: id });
    },
    logout() {
      this.token = '';
      this.email = '';
      removeToken();
      router.push({ path: '/login' });
    },
  },
});

export function useUserStoreHook() {
  return useUserStore(store);
}
