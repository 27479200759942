import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'virtual:svg-icons-register'; // 注册icon
import { setupStore } from '@/store';
// import 'element-plus/dist/index.css';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import NProgress from 'nprogress';
// 导入样式
import '@/styles/element/index.scss';
import 'normalize.css';
import 'nprogress/nprogress.css';
// import 'animate.css';
import '@/styles/reset.scss';
import '@/styles/index.scss';
import '@/assets/iconfont/iconfont.js';

const app = createApp(App);

NProgress.configure({
  // 动画方式
  easing: 'ease',
  // 递增进度条的速度
  speed: 500,
  // 是否显示加载ico
  showSpinner: false,
  // 自动递增间隔
  trickleSpeed: 200,
  // 初始化时的最小百分比
  minimum: 0.3,
});

setupStore(app);
app.use(ElementPlus, { locale: zhCn });
app.use(router);
app.mount('#app');
